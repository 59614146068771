import React from "react";
import logo from "../../assets/Lokshin.png";
import { useAuth } from "utils/authContext/auth";
import NavLink from "./NavLink";
import { clsx } from "clsx";
import { useNav } from "../../hooks/useNav";
import {
  BiLogOut,
  BiGridAlt,
  BiGroup,
  BiHomeAlt,
  BiUser,
  BiChevronLeft,
  BiReceipt,
  BiEnvelope,
  BiImages,
  BiHelpCircle,
  BiData,
} from "react-icons/bi";
import { accountActions, serverActions } from "../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const NavigationBar = ({ children, account }) => {
  const auth = useAuth();
  const { isOpen, closeMenu } = useNav();
  return (
    <div>
      <div className="h-[7vh] w-full bg-white flex px-4 justify-between items-center">
        <img alt="" src={logo} className={clsx("w-40 my-auto")} />
        <div>
          <img
            alt=""
            src={account.profilePicture}
            className={clsx("w-10 h-10 object-cover my-auto rounded-full mr-4")}
          />
        </div>
      </div>
      <div className="flex h-full bg-white w-full overflow-hidden ">
        <div
          className={clsx(
            "w-[250px] pt-12 pb-6 px-4 pr-6 flex justify-between flex-col relative transition-all duration-500 ",
            { "!w-[110px]": !isOpen }
          )}
        >
          <div
            className="w-10 h-10 bg-slate-400 absolute -right-5 top-[45%] rounded-full cursor-pointer flex justify-center items-center"
            onClick={closeMenu}
          >
            <BiChevronLeft
              size={30}
              color="#fff"
              className={clsx("transition-all duration-300", {
                " rotate-180": !isOpen,
              })}
            />
          </div>
          <div>
            <div className="mb-14">
              <div
                className={clsx("text-[10px] uppercase mb-2", {
                  "text-center": !isOpen,
                })}
              >
                Main Menu
              </div>
              <NavLink
                Icon={BiGridAlt}
                title="Dashboard"
                isOpen={isOpen}
                link="/app"
              />
              <NavLink
                Icon={BiGroup}
                title="Users"
                isOpen={isOpen}
                link="/app/users"
              />
              <NavLink
                Icon={BiHomeAlt}
                title="Properties"
                isOpen={isOpen}
                link="/app/properties"
              />
              <NavLink
                Icon={BiReceipt}
                title="Requests"
                isOpen={isOpen}
                // link="/app/properties"
              />
              {/*Messsages from help  */}

              <NavLink
                Icon={BiEnvelope}
                title="Messages"
                isOpen={isOpen}
                // link="/app/properties"
              />
              {/*All images uploaded */}

              <NavLink
                Icon={BiImages}
                title="Media"
                isOpen={isOpen}
                // link="/app/properties"
              />
            </div>
            <div>
              <div
                className={clsx("text-[10px] uppercase mb-2", {
                  "text-center": !isOpen,
                })}
              >
                Settings
              </div>
              {/* ADMINS CHANGING THEIR USER DETAILS */}

              <NavLink
                Icon={BiUser}
                title="Account"
                isOpen={isOpen}
                link={"/app/account"}
              />
              {/* HELP FOR ADMINS */}
              <NavLink
                Icon={BiData}
                title="Logs"
                isOpen={isOpen}
                link={"/app/settings/logs"}
              />
              <NavLink
                Icon={BiHelpCircle}
                title="Help"
                isOpen={isOpen}
                link={"/app/settings/help"}
              />
            </div>
          </div>
          <NavLink
            title=" Log out"
            isOpen={isOpen}
            Icon={BiLogOut}
            onClick={() => auth.logout()}
          />
        </div>
        <div className="bg-gray-100 h-[93vh] w-full overflow-auto p-7">
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  server: state.server,
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  serverActions: bindActionCreators(serverActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
