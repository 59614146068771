import { Route } from "react-router-dom";
import { Auth, NotFoundPage } from "../pages";

export default (
  <>
    <Route path={`*`} element={<NotFoundPage />} />
    <Route path={`/`} element={<Auth.Login />} />
    <Route path={`/login`} element={<Auth.Login />} />
  </>
);
