import { useMutation, useQuery } from "@apollo/client";
import { DELETE_USER, DISABLE_USER } from "graphql-actions/mutation/user";
import { GET_ALL_USERS } from "graphql-actions/queries/user";

export const useGetUsers = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS);
  return {
    userData: data?.getAllUsers ?? [],
    userLoading: loading,
    userError: error,
    refetch,
  };
};

export const useDeleteUser = () => {
  const [deleteUserById, { data, loading, error }] = useMutation(DELETE_USER);
  console.log("loading", loading);
  console.log("data", data);
  console.log("error", error);
  return {
    data: data?.deleteUser,
    loading,
    error,
    deleteUserById,
  };
};

export const useDisableUser = () => {
  const [disableUser, { data, loading, error }] = useMutation(DISABLE_USER);
  return {
    data: data?.toogleUserActivation,
    loading,
    error,
    disableUser,
  };
};
