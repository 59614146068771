export const ADD_TOKEN = "ADD_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const ADD_ACCOUNT_DETAILS = "ADD_ACCOUNT_DETAILS";
export const UPDATE_ACCOUNT_DETAILS = "UPDATE_ACCOUNT_DETAILS";
export const REMOVE_ACCOUNT_DETAILS = "REMOVE_ACCOUNT_DETAILS";

export const SET_USERS = "SET_USERS";
export const SET_USER_MESSAGES = "SET_USER_MESSAGES";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_REACTION = "ADD_REACTION";

export const ADD_FAVORITES = "ADD_FAVORITES";
export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES";

export const ADD_CART = "ADD_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const ADD_ADDRESSES = "ADD_ADDRESSES";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const MARK_ADDRESS_AS_PRIMARY = "MARK_ADDRESS_AS_PRIMARY";
export const REMOVE_ADDRESS = "REMOVE_ADDRESS";

export const ADD_ORDERS = "ADD_ORDERS";
export const ADD_ORDER = "ADD_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";

export const ADD_MENUITEMS = "ADD_MENUITEMS";
export const ADD_MENUITEM = "ADD_MENUITEMS";
export const UPDATE_MENUITEM = "UPDATE_MENUITEM";
export const REMOVE_MENUITEM = "REMOVE_MENUITEM";

export const SWITCH_SERVER = "SWITCH_SERVER";
export const REFRESH_SERVER = "REFRESH_SERVER";
