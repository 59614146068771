import { ADD_TOKEN, REMOVE_TOKEN } from "./actionTypes";

// TOKEN ACTIONS
export const addToken = (token) => ({
  type: ADD_TOKEN,
  token,
});

export const removeToken = (id) => ({
  type: REMOVE_TOKEN,
  id,
});
