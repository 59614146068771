import { gql } from "@apollo/client";

export const CREATE_REQUEST = gql`
  mutation ($input: RequestInput!) {
    createRequest(input: $input) {
      _id
      requester {
        _id
      }
      requestee {
        _id
      }
      room {
        _id
      }
      viewDate
      status
      createdDate
    }
  }
`;
