import React, { useState } from "react";
import { StyledInputLabel } from "./textField.styles";
import { useField } from "formik";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Password = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [passwordShow, setPasswordShow] = useState(false);

  const PasswordIcon = passwordShow ? BsEye : BsEyeSlash;
  return (
    <div style={{ position: "relative" }}>
      <StyledInputLabel>{label}</StyledInputLabel>
      <input
        className="custom-input"
        style={{
          borderColor: meta.touched && meta.error ? "red" : "#e5e5e5",
        }}
        autoComplete="new-password"
        type={passwordShow ? "text" : "password"}
        {...props}
        {...field}
      />
      <PasswordIcon
        onClick={() => setPasswordShow(!passwordShow)}
        size={20}
        style={{ position: "absolute", cursor: "pointer", top: 30, right: 10 }}
      />

      {meta.touched && meta.error ? (
        <div className="input-error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Password;
