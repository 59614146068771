import React from "react";

const ShowEntries = ({ state, setPageSize, entriesList = [5, 10, 25, 50] }) => {
  return (
    <div className="flex items-center">
      <div className="text-xs font-semibold">Show</div>
      <select
        value={state.pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        className="px-2 border rounded-md mx-2 text-sm cursor-pointer"
      >
        {entriesList.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
      <div className="text-xs font-semibold">entries</div>
    </div>
  );
};

export default ShowEntries;
