import React, { useCallback, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Button from "../../button";

const Modal = ({
  isOpen = true,
  onClose,
  onSubmit,
  title,
  body,
  footer,
  actionLabel,
  disabled,
  secondaryAction,
  secondaryActionLabel,
  actionClass,
  secondaryActionClass,
  secondaryActionLoading,
  actionLoading,
}) => {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (disabled) return;
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [disabled, onClose]);

  const handleSubmit = useCallback(() => {
    if (disabled) return;
    onSubmit();
  }, [disabled, onSubmit]);

  const handleSecondaryAction = useCallback(() => {
    if (disabled || !secondaryAction) return;
    secondaryAction();
  }, [disabled, secondaryAction]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-neutral-800/70">
      <div className="relative w-fit-content md:w-4/6 lg:w-2/5 xl:w-2/5 my-6 mx-auto h-full lg:h-auto md-h-auto">
        {/* content */}
        <div
          className={`translate duration-300 h-full ${
            showModal ? "translate-y-0" : "translate-y-full"
          } ${showModal ? "opacity-100" : "opacity-0"}`}
        >
          <div className="translate h-full lg:h-auto md:h-auto border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            {/* Header */}
            <div className="flex items-center py-4 px-6 justify-center relative border-b">
              <div className="text-lg font-semibold">{title}</div>
              <button
                onClick={handleClose}
                className="p-1 border-none hover:opacity-70 transition absolute right-0"
              >
                <IoMdClose size={18} />
              </button>
            </div>
            {/* Body */}
            <div className="relative py-4 px-6 flex-auto">{body}</div>
            {/* Footer */}
            <div className="flex flex-col py-3 p-6 gap-2 bg-neutral-50 rounded-b-lg">
              <div className="flex items-center gap-4 w-full">
                {secondaryAction && secondaryActionLabel && (
                  <Button
                    className={secondaryActionClass ?? "small primary"}
                    disabled={disabled || secondaryActionLoading}
                    loading={secondaryActionLoading}
                    title={secondaryActionLabel}
                    onClick={handleSecondaryAction}
                  />
                )}
                <Button
                  className={actionClass ?? "small dark"}
                  disabled={disabled || actionLoading}
                  loading={actionLoading}
                  title={actionLabel}
                  onClick={handleSubmit}
                />
              </div>
              {footer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
