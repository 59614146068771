import React from "react";
import { TailSpin } from "react-loader-spinner";

const Button = ({
  className,
  title,
  type = "button",
  disabled,
  onClick,
  loading,
  leftIcon,
  rightIcon,
}) => {
  return (
    <button
      className={className}
      type={loading ? "button" : type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <div style={{ marginRight: 5 }}>
          <TailSpin
            className="bg-red"
            height={15}
            width={15}
            color="#fff"
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#7CB73F"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      )}
      {!loading && leftIcon && <div style={{ marginRight: 5 }}>{leftIcon}</div>}
      {title}
      {rightIcon && <div style={{ marginLeft: 5 }}>{rightIcon}</div>}
    </button>
  );
};

export default Button;
