import React from "react";
import { BiHome } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import { clsx } from "clsx";

const NavLink = ({ Icon = BiHome, title, isOpen, link, onClick }) => {
  const nav = useNavigate();
  const location = useLocation();

  const isActive = () => {
    let linkArray = location.pathname.split("/");
    linkArray = linkArray.filter((x) => x !== "");
    if (linkArray.length > 1) {
      return linkArray[1].includes(title?.toLowerCase());
    }
    return linkArray[0] === link?.replace("/", "");
  };

  return (
    <div
      onClick={() => (onClick ? onClick() : nav(link))}
      className={clsx(
        "hover:font-bold flex text-sm p-2 rounded-md cursor-pointer transition-all duration-300  hover:bg-stone-200 items-center mb-1",
        {
          "justify-center text-center": !isOpen,
          "bg-stone-200 font-bold": isActive(),
        }
      )}
    >
      <Icon
        className={clsx("text-lg text-[#8a8a8a] ", {
          "text-lg text-center hover:text-[#000]": !isOpen,
          "text-[#000]": isActive() && !isOpen,
        })}
      />
      <div
        className={clsx("text-black text-[13px] ml-2 mb-auto", {
          "!text-white !text-[0px] !ml-0": !isOpen,
        })}
      >
        {title}
      </div>
    </div>
  );
};

export default NavLink;
