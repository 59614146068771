import React, { useCallback } from "react";

import { BiEditAlt, BiTrashAlt } from "react-icons/bi";
import moment from "moment";
import { formatter } from "utils";
import { clsx } from "clsx";
import { useGetProperties } from "../../../../hooks/useGetProperties";

const usePropertyTable = () => {
  const { roomData, roomLoading, refetch } = useGetProperties();

  const onEdit = useCallback((data) => {
    alert("on edit");
    return data;
  }, []);

  const onDelete = useCallback((data) => {
    alert("on delete");

    return data;
  }, []);

  const columns = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Name",
        accessor: "firstName",
        sortType: "basic",
        Cell: ({ row }) => {
          const {
            original: { user },
          } = row;
          return (
            <div className="flex items-center">
              <img
                alt=""
                src={user?.profilePicture}
                className="w-8 h-8 rounded-full object-cover ml-1"
              />
              <div className="ml-2 capitalize">
                <div>{user?.firstName + " " + user?.lastName}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Address",
        sortType: "basic",
        accessor: ({ address }) => address.fullAddress?.toLowerCase(),
        Cell: ({ row }) => {
          const {
            original: { address },
          } = row;
          return (
            <div className="line-clamp-1">
              <div>{address?.fullAddress}</div>
            </div>
          );
        },
      },
      {
        Header: "Type",
        sortType: "basic",
        accessor: "rentalType",
      },
      {
        Header: "Rent",
        sortType: "basic",
        accessor: "rent",
        Cell: ({ row }) => formatter.toRand(row.original.rent),
      },
      {
        Header: "Available",
        accessor: "status",
        sortType: "basic",
        Cell: ({ row }) => {
          const {
            original: { status },
          } = row;
          return (
            <div
              className={clsx(
                "bg-red-200 text-red-700 p-2 text-center rounded-md capitalize w-20",
                {
                  "!bg-lime-200 !text-lime-700": status,
                }
              )}
            >
              {formatter.booleanToString(status)}
            </div>
          );
        },
      },

      {
        Header: "Created Date",
        accessor: ({ createdDate }) => moment(createdDate).format("LL"),
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="flex">
              <div
                className="mr-6 cursor-pointer"
                onClick={() => onEdit(original)}
              >
                <BiEditAlt size={20} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => onDelete(original)}
              >
                <BiTrashAlt size={20} />
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return {
    data: roomData ?? [],
    columns,
    loading: roomLoading,
    refetch,
  };
};

export default usePropertyTable;
