import { useNavigate } from "react-router-dom";
import { useAuth } from "./auth";
import { useEffect } from "react";
import { FullPageLoader, NavigationBar } from "components";
import { useTokenValidation } from "../../hooks/useTokenValidation";
import { useIdleTimer } from "../../hooks/useIdleTimer";

export const RequireAuth = ({ children, navbar }) => {
  const auth = useAuth();
  const nav = useNavigate();
  const { isValid, loading } = useTokenValidation(auth.localToken);
  const { isIdle, IdleScreen } = useIdleTimer();

  const logoutAndNav = () => {
    auth.logout();
    return nav("/login");
  };

  useEffect(() => {
    if (!isValid && !loading) {
      logoutAndNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, isValid, loading, nav]);

  if (loading) {
    return <FullPageLoader loading={true} />;
  } else {
    if (!auth.localToken) {
      return logoutAndNav();
    } else {
      if (isIdle) return <IdleScreen />;
      return <NavigationBar children={children} />;
    }
  }
};
