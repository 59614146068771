import { Button } from "components";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const nav = useNavigate();
  return (
    <div className="h-screen bg-black text-white text-center flex flex-col justify-center items-center">
      <div className="text-[15rem] font-semibold -mb-14">404</div>
      <div className="text-6xl font-semibold mb-10">You seem lost</div>
      <Button
        className="lg light w-1/4"
        title="Go Home"
        onClick={() => nav("/app")}
      />
    </div>
  );
};
