import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { BiSortAZ, BiSortAlt2, BiSortZA } from "react-icons/bi";
import { Alert, Button, Table } from "components";
import clsx from "clsx";
import useUserTable from "./useUserTable";
import { ConfirmationModal } from "components/Modals";
import { useDeleteUser } from "../../../../hooks/useUsers";

const Users = () => {
  const {
    columns,
    data,
    loading,
    selectedDeleteUser,
    selectedUser,
    setSelectedDeleteUser,
    setSelectedUser,
    refetch,
    error: disableUserError,
  } = useUserTable();
  const [isOpen, setIsOpen] = useState(false);
  const {
    deleteUserById,
    loading: deleteUserLoading,
    error: deleteUserError,
  } = useDeleteUser();
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    {
      autoResetSortBy: false,
      autoResetPage: false,
      columns,
      data,
      getRowId: (row, index) => (row.id = index + 1),
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter } = state;

  useEffect(() => {
    if (selectedDeleteUser) {
      console.log("selectedDeleteUser", selectedDeleteUser);

      setIsOpen(true);
    }
    if (selectedUser) {
      console.log("selectedUser", selectedUser);
    }
  }, [selectedDeleteUser, selectedUser]);

  useEffect(() => {
    if (!deleteUserError && !deleteUserLoading && selectedDeleteUser) {
      refetch();
      onClose();
    } else if (deleteUserError) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserById, deleteUserLoading]);

  const onDelete = async () => {
    return await deleteUserById({
      variables: {
        _id: selectedDeleteUser._id,
      },
    });
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedDeleteUser(null);
    setSelectedUser(null);
  };

  return (
    <div className="bg-white rounded-md p-6 pt-2 h-full">
      <ConfirmationModal
        isOpen={isOpen}
        secondaryActionLabel="Yes Delete User"
        secondaryAction={onDelete}
        onSubmit={onClose}
        onClose={onClose}
        secondaryActionClass={"small danger"}
        bodyChild={
          <span className="text-sm">
            Are you sure you want to{" "}
            <span className="text-red-600 font-semibold">delete</span> user
            account? All of their data will be permantently removed. This action
            cannnot be undone.
          </span>
        }
        secondaryActionLoading={deleteUserLoading}
      />
      <Alert
        error={deleteUserError || disableUserError}
        isShow={deleteUserError || disableUserError}
      />
      <div className="flex mt-4 mb-4 justify-between items-end">
        <Table.ShowEntries state={state} setPageSize={setPageSize} />
        <div className="flex">
          <Table.Search
            placeholder={"Search name, contact or etc"}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
          <Button className="primary small ml-4" title="Add new user" />
        </div>
      </div>
      <table
        {...getTableProps()}
        className="w-full border border-gray-200 block rounded-lg overflow-hidden"
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="pl-4">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    className={clsx("text-left pl-4 py-3 text-sm w-[17vw]", {
                      "!w-[10vw]":
                        column.id === "action" || column.id === "Type",
                      "!w-[5vw] ": column.id === "id",
                      "!w-[8vw] ": column.id === "active",
                      "border-gray-300 ": loading,
                    })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex items-center">
                      <div className="w-full">{column.render("Header")}</div>
                      <div className="mx-auto pr-6">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BiSortZA />
                          ) : (
                            <BiSortAZ />
                          )
                        ) : (
                          column.canSort && <BiSortAlt2 />
                        )}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={clsx(
            "block w-full table-fixed overflow-auto  bg-slate-100 mx-auto h-[60vh] transition-all duration-300",
            {
              "!min-h-[33vh] !h-[33vh]":
                loading || !page.length || !data.length || page.length < 6,
            }
          )}
        >
          <Table.Status data={page} loading={loading} />
          {!loading &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white  border-t border-gray-200 cursor-pointer hover:bg-gray-50 transition-all duration-300"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={clsx("p-4 text-xs w-[17vw]", {
                        "!w-[10vw]":
                          cell.column.id === "action" ||
                          cell.column.id === "Type",
                        "!w-[5vw] ": cell.column.id === "id",
                        "!w-[8vw] ": cell.column.id === "active",
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="flex justify-between">
        <Table.ResultsLength data={data} state={state} page={page} />
        <Table.Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          previousPage={previousPage}
          state={state}
        />
      </div>
    </div>
  );
};

export default Users;
