import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const GET_ALL_LOGS = gql`
  query getAllLogs {
    getAllLogs {
      level
      message
      meta
      ipAddress
      timestamp
      createdDate
    }
  }
`;

export const useGetLogs = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_LOGS);
  return {
    data: data?.getAllLogs ?? [],
    loading,
    error,
    refetch,
  };
};
