import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input } from "components";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth } from "utils/authContext/auth";
import {
  accountActions,
  authActions,
  serverActions,
} from "../../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useValidationSchema } from "./hook/use-validation-schema";
import { useLazyQuery } from "@apollo/client";
import { FaChevronDown } from "react-icons/fa";
import { urls } from "utils/resuableLists";
import { QUERY } from "graphql-actions";

const Login = ({ authActions, accountAction, server, serverActions }) => {
  const [login, { data, loading, error }] = useLazyQuery(
    QUERY.USER.SIGN_IN_ADMIN
  );
  const [isOpen, setIsOpen] = useState(false);
  const nav = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.localToken) {
      return nav("/app", { replace: true });
    }
  }, [auth.localToken, nav]);

  useEffect(() => {
    if (data?.signinAdmin?.token) {
      authActions.addToken(data.signinAdmin.token);
      auth.login(data.signinAdmin.token);

      return nav("/app", { replace: true });
    }
  }, [auth, authActions, data, error, nav]);

  const validationSchema = useValidationSchema();
  const formik = useFormik({
    validationSchema,
    initialValues: { email: "", password: "" },
    onSubmit: (values) => login({ variables: { ...values } }),
  });

  const changeServer = (index) => {
    serverActions.switchServer(index);
    return window.location.reload();
  };
  return (
    <div className=" h-screen flex relative">
      {!window.location.hostname.includes("lokshinrentals") && (
        <div
          className="absolute left-10 top-10"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center bg-black p-3 text-white rounded-md cursor-pointer">
            <div className="mr-2 text-sm">Active server: {server.env}</div>
            <FaChevronDown />
          </div>
          {isOpen && (
            <div className="bg-black rounded-md text-white cursor-pointer">
              {urls.map((serve, index) => (
                <div
                  className="text-sm mt-2 py-2 pl-4 hover:bg-white hover:text-black cursor-pointer"
                  onClick={() => changeServer(index)}
                >
                  {serve.env}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="w-1/3 h-full bg-[url('https://cdn-hpbep.nitrocdn.com/HdGeOaZIWMPmGoBNHklvJlSjKTlutkbA/assets/desktop/optimized/rev-061b42b/26d03168d0c7ff8dc12f661938117caa.photo-1592817469900-ddf1bdd23205')]"></div>
      <div className="bg-white w-2/3 h-full flex flex-col justify-center items-center">
        <div className="w-1/3">
          <Alert error={error} />
          <Form name="login-form" formik={formik}>
            <Input.TextField name="email" label="Email" />
            <Input.Password name="password" label="Password" />
            <div className="mb-4 text-xs text-gray-500 cursor-pointer">
              Forgot Password?
            </div>
            <Button
              className="primary w-full"
              title="Login"
              type="submit"
              loading={loading}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  server: state.server,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
  serverActions: bindActionCreators(serverActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
