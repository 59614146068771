import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation ($profilePicture: String, $input: UserInput) {
    updateProfile(profilePicture: $profilePicture, input: $input) {
      _id
      firstName
      lastName
      email
      phoneNumber
      type
      profilePicture
      status
      createdDate
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($_id: ID!) {
    deleteUser(_id: $_id)
  }
`;

export const DISABLE_USER = gql`
  mutation toogleUserActivation($_id: ID!) {
    toogleUserActivation(_id: $_id)
  }
`;
