import React, { useState } from "react";
import { useGetUsers, useDisableUser } from "../../../../hooks/useUsers";
import { BiEditAlt, BiTrashAlt } from "react-icons/bi";
import moment from "moment";
import { UserType } from "utils";
import { clsx } from "clsx";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const useUserTable = () => {
  const [selectedDeleteUser, setSelectedDeleteUser] = useState(null);
  const { disableUser, error } = useDisableUser();
  const [selectedUser, setSelectedUser] = useState(null);
  const { userData, userLoading, refetch } = useGetUsers();
  const columns = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
        sortType: "basic",
        minWidth: "10vh",
      },
      {
        Header: "Name",
        accessor: ({ firstName }) => firstName?.toLowerCase(),
        sortType: "basic",
        Cell: ({ row }) => {
          const {
            original: { firstName, lastName, profilePicture },
          } = row;
          return (
            <div className="flex items-center">
              <img
                alt=""
                src={profilePicture}
                className="w-8 h-8 rounded-full object-cover ml-1"
              />
              <div className="ml-2 capitalize">
                <div>{firstName + " " + lastName}</div>
                <div className="text-gray-500">{firstName}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Contact",
        sortType: "basic",
        accessor: ({ email }) => email?.toLowerCase(),
        Cell: ({ row }) => {
          const {
            original: { email, phoneNumber },
          } = row;
          return (
            <div>
              <div>{email?.toLowerCase()}</div>
              <div className="text-gray-500">{phoneNumber}</div>
            </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: ({ type }) => type?.toLowerCase(),
        sortType: "basic",
        Cell: ({ row }) => {
          const {
            original: { type },
          } = row;
          return (
            <div
              className={clsx("p-2 text-center rounded-md capitalize w-20", {
                "bg-lime-100 text-lime-700": type === UserType.LANDLORD,
                "bg-[#8a8a8a40] text-[#4a4a4a]": type === UserType.TENANT,
              })}
            >
              {type}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "active",
        sortType: "basic",
        Cell: ({ row }) => (
          <div
            className={clsx("text-md font-seminbold transition duration-75", {
              "text-lime-500": row.original.active,
              "text-red-500": !row.original.active,
            })}
          >
            {row.original.active ? "Active" : "Inactive"}
          </div>
        ),
      },
      {
        Header: "Created Date",
        accessor: ({ createdDate }) => moment(createdDate).format("LL HH:MM:A"),
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          const Icon = original?.active ? BsToggleOn : BsToggleOff;
          return (
            <div className="flex items-center">
              <div
                className="mr-3 cursor-pointer text-lime-500"
                onClick={() => setSelectedUser(original)}
              >
                <BiEditAlt size={20} />
              </div>
              <div
                className="mr-3 cursor-pointer text-red-500"
                onClick={() => setSelectedDeleteUser(original)}
              >
                <BiTrashAlt size={20} />
              </div>
              <div
                className={clsx("cursor-pointer transition duration-75", {
                  "text-green-500": original.active,
                  "text-black": !original.active,
                })}
                onClick={async () => {
                  await disableUser({
                    variables: {
                      _id: original._id,
                    },
                  });
                  await refetch();
                }}
              >
                <Icon size={24} className="transition duration-300" />
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return {
    data: userData ?? [],
    columns,
    loading: userLoading,
    refetch,
    selectedDeleteUser,
    selectedUser,
    setSelectedDeleteUser,
    setSelectedUser,
    error,
  };
};

export default useUserTable;
