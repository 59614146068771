import { Route } from "react-router-dom";
import { RequireAuth } from "utils/authContext/requireAuth";
import { App } from "../pages";

export const ProtectedRoutes = [
  {
    path: "/app",
    Element: App.Home,
  },
  {
    path: "/app/users",
    Element: App.User.Users,
  },
  {
    path: "/app/properties",
    Element: App.Property.Properties,
  },
  {
    path: "/app/properties/:id",
    Element: App.Property.Property,
  },
  {
    path: "/app/account",
    Element: App.Account,
  },
  {
    path: "/app/settings/logs",
    Element: App.Settings.Logs,
  },
  {
    path: "/app/settings/help",
    Element: App.Settings.Help,
  },
];

export default ProtectedRoutes.map(({ path, Element }) => (
  <Route
    path={path}
    element={
      <RequireAuth>
        <Element />
      </RequireAuth>
    }
  />
));
