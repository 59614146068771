import { SWITCH_SERVER, REFRESH_SERVER } from "./actionTypes";

// ACCOUNT ACTIONS
export const switchServer = (number) => ({
  type: SWITCH_SERVER,
  number,
});

export const refreshServer = () => ({
  type: REFRESH_SERVER,
});
