import React from "react";
import { StyledInputLabel } from "./textField.styles";
import { useField } from "formik";

const TextField = ({ label, type = "text", ...props }) => {
  const [field, meta] = useField(props);
  const keyboardType = () => {
    if (type === "number") {
      return "numeric";
    }
    if (type === "email") {
      return "email";
    }
    return "text";
  };

  return (
    <>
      <StyledInputLabel>{label}</StyledInputLabel>
      {type === "textarea" ? (
        <textarea
          className="custom-input"
          rows={props.rows ?? 6}
          style={{
            padding: "10px 20px",
            borderColor: meta.touched && meta.error ? "red" : "#e5e5e5",
          }}
          {...props}
          {...field}
        />
      ) : (
        <input
          className="custom-input"
          autoComplete="new-password"
          inputMode={keyboardType()}
          style={{
            borderColor: meta.touched && meta.error ? "red" : "#e5e5e5",
          }}
          type={type}
          min={0}
          {...props}
          {...field}
        />
      )}
      {meta.touched && meta.error ? (
        <div className="input-error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default TextField;
