import { gql } from "@apollo/client";

export const SIGN_IN_ADMIN = gql`
  query ($email: String!, $password: String!) {
    signinAdmin(email: $email, password: $password) {
      token
    }
  }
`;

export const IS_TOKEN_VALID = gql`
  query isTokenValid {
    isTokenValid
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      _id
      firstName
      lastName
      email
      phoneNumber
      type
      profilePicture
      status
      createdDate
      active
    }
  }
`;
export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      email
      lastName
      firstName
      phoneNumber
      _id
      type
      createdDate
      status
      profilePicture
      active
    }
  }
`;
