export const urls = [
  {
    env: "Local",
    server: process.env.REACT_APP_API,
    wsServer: process.env.REACT_APP_WS_API,
  },
  {
    env: "Staging",
    server: process.env.REACT_APP_STAGING_API,
    wsServer: process.env.REACT_APP_STAGING_WS_API,
  },
  {
    env: "Production",
    server: process.env.REACT_APP_PRODUCTION_API,
    wsServer: process.env.REACT_APP_PRODUCTION_WS_API,
  },
];
