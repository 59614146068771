import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetProperties } from "../../../hooks/useGetProperties";
import { useGetUsers } from "../../../hooks/useUsers";
import { useGetCurrentUser } from "../../../hooks/useGetCurrentUser";
import { StatsCard } from "components";
import { UserType } from "utils";

const Home = () => {
  const { userData, userLoading, userError } = useGetUsers();
  const { roomData, roomLoading, roomError } = useGetProperties();
  useGetCurrentUser();
  const nav = useNavigate();

  const error = userError || roomError;

  return (
    <div className="h-full">
      {/* <div className="h-20 w-full bg-white"></div> */}
      <div className="w-8/12 bg-gray-100">
        {error && (
          <div className="my-6 text-red-500 font-bold text-2xl mx-auto">
            {error.message}
          </div>
        )}
        <div>
          <div className="flex">
            <StatsCard
              title="Total users"
              data={userData}
              loading={userLoading}
              onPress={() => nav("users")}
            />
            <StatsCard
              title="Total landlords"
              data={userData?.filter(
                (user) => user?.type === UserType.LANDLORD
              )}
              loading={userLoading}
              onPress={() => nav("users")}
            />
            <StatsCard
              title="Total tenants"
              data={userData?.filter((user) => user?.type === UserType.TENANT)}
              loading={userLoading}
              onPress={() => nav("users")}
            />
            <StatsCard
              title="Total properties"
              data={roomData ?? []}
              loading={roomLoading}
              onPress={() => nav("properties")}
            />
          </div>
          <div className="w-3/3 bg-white p-4 text-white rounded-md mr-4 mt-6  cursor-pointer flex flex-col min-h-[140px] shadow-md"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
