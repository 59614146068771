import { Form, FormikProvider } from "formik";
import { FormContext } from "./context";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { formik, name, ...otherProps } = props;

  return (
    <FormikProvider value={formik}>
      <FormContext.Provider value={{ name }}>
        <Form data-cy={name} {...otherProps} />
      </FormContext.Provider>
    </FormikProvider>
  );
};
