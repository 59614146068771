import clsx from "clsx";
import moment from "moment/moment";
import React, { useMemo } from "react";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
import { ClipLoader } from "react-spinners";

const StatsCard = ({ data, title, loading = true, onPress }) => {
  const percentage = useMemo(() => {
    const lastMonth = moment(new Date())
      .subtract(1, "months")
      .startOf("months")
      .format();
    const thisMonth = moment(new Date()).startOf("months").format();
    const lastMonthData = data?.filter(({ createdDate }) =>
      moment(createdDate).isSame(lastMonth, "month")
    );
    const thisMonthData = data?.filter(({ createdDate }) =>
      moment(createdDate).isSame(thisMonth, "month")
    );
    if (!thisMonthData.length || !lastMonthData.length) {
      return (thisMonthData.length - lastMonthData.length) * 100;
    }
    return (
      ((thisMonthData.length - lastMonthData.length) / lastMonthData.length) *
      100
    );
  }, [data]);

  const NoChange = percentage === 0;
  const Icon = percentage > 0 ? BiTrendingUp : BiTrendingDown;
  return (
    <div
      onClick={onPress}
      className="w-1/3 max-w-[260px] bg-white p-4 text-white rounded-md mr-4 cursor-pointer flex flex-col min-h-[140px] shadow-md"
    >
      {!loading && (
        <>
          <div className="text-[#6a6a6a] text-sm font-medium">{title}</div>
          <div className="text-black font-bold text-4xl my-3">
            {data?.length}
          </div>
          <div
            className={clsx("bg-emerald-50 w-fit py-1 px-3 rounded-lg flex", {
              "!bg-red-100": percentage < 0,
              "!bg-gray-100": NoChange,
            })}
          >
            <span
              className={clsx("text-xs text-emerald-500 font-bold mx-auto", {
                "!text-red-500": percentage < 0,
                "!text-gray-500": NoChange,
              })}
            >
              {percentage.toFixed(2)}%
            </span>
            {!NoChange && (
              <Icon
                className={clsx("text-emerald-500 mt-[2px] ml-[2px]", {
                  "!text-red-500 ": percentage < 0,
                  "!text-gray-100": NoChange,
                })}
              />
            )}
          </div>
        </>
      )}
      {loading && <ClipLoader color="#7cb73f" className="m-auto" />}
    </div>
  );
};

export default StatsCard;
