import { urls } from "../../utils/resuableLists";
const isLive = window.location.hostname.includes("lokshinrentals");
const server = (state = urls[!isLive ? 1 : 2], action) => {
  switch (action.type) {
    case "SWITCH_SERVER":
      return urls[action.number];
    case "REFRESH_SERVER":
      return urls[!isLive ? 1 : 2];
    default:
      return state;
  }
};

export default server;
