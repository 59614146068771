import account from "./account";
// import token from './token';
// import address from './address';
import favourites from "./favourites";
// import cart from './cart';
// import messages from './messages';
// import orders from './order';
import server from "./server";
const { combineReducers } = require("redux");

export default combineReducers({
  account,
  favourites,
  server,
});
