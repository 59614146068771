export const formatter = {
  toRand: (value) => {
    value = value ? value : 0;
    let amount = value.toLocaleString("en-US", {
      style: "currency",
      currency: "ZAR",
    });
    amount = amount.replace("ZAR", "R");
    return amount.replace(".00", "");
  },
  searchSplitter: (searchString, value) => {
    let cleanData = searchString?.split("?");
    cleanData = cleanData.find((x) => x.includes(value));
    cleanData = cleanData.replace(`${value}=`, "");
    cleanData = cleanData.replace(`%20`, " ");
    return cleanData;
  },
  toBoolean: (value) => (value ? value.toLowerCase() === "yes" : false),
  booleanToString: (value) => (value ? "Yes" : "No"),
  propertUrl: (property) => {
    const township = property?.address.fullAddress.split(", ")?.[1];
    const city = property?.address.fullAddress.split(", ")?.[2];
    const url = `${city.toLowerCase()}/${township
      ?.toLowerCase()
      .replace(" ", "-")}/${property._id}`;
    return `/properties/property/${url}`;
  },
};
