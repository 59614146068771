import * as yup from "yup";

export const useValidationSchema = (activeStep) => {
  const requiredMessage = "Please fill in the required field.";

  return yup.object({
    email: yup.string().required(requiredMessage).nullable(),
    password: yup.string().required(requiredMessage).nullable(),
  });
};
