import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { store } from "./store";

export default function ApolloProvider(props) {
  let uploadLink = createUploadLink({
    maxFileSize: 50 * 1024 * 1024,
    maxFiles: 6,
    uri: store.getState().server.server,
  });

  const authLink = setContext((_, { headers }) => {
    // // get the authentication token from local storage if it exists
    return {
      headers: {
        ...headers,
        authorization: localStorage.getItem("token"),
      },
    };
  });

  uploadLink = authLink.concat(uploadLink);
  const wsLink = new WebSocketLink({
    uri: store.getState().server.wsServer,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: {
        authToken: localStorage.getItem("token"),
        headers: {
          authorization: localStorage.getItem("token"),
        },
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query).operation;
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    uploadLink
  );
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  };

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: defaultOptions,
  });

  return <Provider client={client} {...props} />;
}
