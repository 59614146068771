import { gql } from "@apollo/client";

export const GET_USER_REQUESTS = gql`
  query getUserRequests {
    getUserRequests {
      _id
      message
      requestType
      requester {
        _id
        firstName
        lastName
        email
        phoneNumber
        type
        profilePicture
        status
        createdDate
      }
      requestee {
        _id
        firstName
        lastName
        email
        phoneNumber
        type
        profilePicture
        status
        createdDate
      }
      room {
        _id
        roomNumber
        rent
        deposit
        meterbox
        parking
        pets
        rentalType
        bathroomType
        availability
        description
        address {
          fullAddress
          placeId
          geo {
            lat
            lng
          }
        }
        status
        active
        views
        images
        createdDate
      }
      viewDate
      status
      createdDate
    }
  }
`;
