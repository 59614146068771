import React from "react";
import { BiSearch } from "react-icons/bi";

const Search = ({ globalFilter, setGlobalFilter, placeholder }) => {
  return (
    <div className="w-[250px] relative">
      <BiSearch
        size={18}
        className="absolute top-[12px] left-[8px] text-gray-400"
      />
      <input
        className="custom-input !mb-0 pl-7 text-xs"
        autoComplete="new-password"
        placeholder={placeholder}
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
      />
    </div>
  );
};

export default Search;
