import React from "react";
import { BarLoader } from "react-spinners";

const FullPageLoader = ({ loading }) => {
  if (!loading) return null;
  return (
    <div className="flex-col flex justify-center m-auto items-center absolute top-0 left-0 h-[100vh] w-full bg-white z-50">
      <img
        className="h-[3rem] w-auto mb-3"
        src={require("../../assets/Lokshin.png")}
        alt="loading logo"
      />
      <BarLoader width={280} height={4} color="#7cb73f" />
    </div>
  );
};

export default FullPageLoader;
