const favourites = (state = [], action) => {
  switch (action.type) {
    case "ADD_FAVORITES":
      return action.rooms;
    case "ADD_TO_FAVORITES":
      const isAdded = state.find((x) => x.id === action.room._id);
      if (isAdded) {
        return state;
      }
      return [...state, action.room];
    case "REMOVE_FROM_FAVORITES":
      return state.filter((x) => x._id !== action.room._id);
    default:
      return state;
  }
};

export default favourites;
