import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import { AuthProvider } from "utils/authContext/auth";
import appRoutes from "./appRoutes";
import authRoutes from "./authRoutes";

export default (
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        {authRoutes}
        {appRoutes}
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);
