import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const GET_ALL_ROOMS = gql`
  query getAllRooms {
    getAllRooms {
      _id
      roomNumber
      user {
        _id
        firstName
        lastName
        email
        type
        createdDate
        profilePicture
      }
      rent
      deposit
      meterbox
      parking
      pets
      rentalType
      bathroomType
      availability
      description
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
      status
      active
      views
      images
      createdDate
    }
  }
`;

export const useGetProperties = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_ROOMS);

  return {
    roomData: data?.getAllRooms ?? [],
    roomLoading: loading,
    roomError: error,
    refetch,
  };
};
