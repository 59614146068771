import { Button } from "components";
import moment from "moment";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useAuth } from "utils/authContext/auth";

export const useIdleTimer = () => {
  const IDLE_TIME = 120;
  const auth = useAuth();
  const [isIdle, setIsIdle] = useState(false);
  const [count, setCount] = useState(IDLE_TIME);
  let timeout = null;
  let countDownTimeout = null;

  const onClose = () => {
    if (isIdle) setIsIdle(false);
    restartAutoReset();
  };

  const restartAutoReset = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => {
      if (!isIdle) setIsIdle(true);
    }, 50 * 6000);
  }, []);

  useEffect(() => {
    const onInteraction = () => {
      restartAutoReset();
    };

    // initiate timeout
    restartAutoReset();

    // listen for events
    window.addEventListener("mousemove", onInteraction);
    window.addEventListener("click", onInteraction);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener("mousemove", onInteraction);
        window.removeEventListener("click", onInteraction);
      }
    };
  }, [restartAutoReset, timeout]);

  useEffect(() => {
    if (isIdle) {
      if (countDownTimeout) {
        clearTimeout(countDownTimeout);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      countDownTimeout = setTimeout(() => {
        if (count !== 0) {
          return setCount(count - 1);
        }
        setCount(IDLE_TIME);
        onClose();
        return auth.logout();
      }, 1000);
    }
  }, [isIdle, count]);
  const countdown = moment.utc(count * 1000).format("mm:ss");

  const IdleScreen = () => (
    <div className=" bg-[#1e1e1e98] h-[100vh] w-full flex justify-center items-center fixed top-0 left-0 z-50 ">
      <div className="shadow-lg bg-white w-fit-content justify-center items-center p-10 pt-8 pb-5 relative rounded-md">
        <IoClose
          size={25}
          className="top-2 right-2 absolute cursor-pointer"
          onClick={onClose}
        />
        <h3 className="text-3xl font-medium mb-6">Inactivity</h3>
        <h3 className="text-md font-medium">
          You have been <strong>inactive</strong> for
          <span className="text-red-600 font-semibold"> 5 minutes.</span>
          <br />
          For security, session will automatically end in{" "}
          <span className="text-red-600 font-semibold">{countdown}</span> unless
          you choose to stay logged in.
        </h3>
        <div className="flex mt-6">
          <Button
            className="bg-red-600 small text-white border-red-600"
            title="Log Out"
            onClick={() => auth.logout()}
          />
          <Button
            className="primary small ml-3"
            title="Stay Logged In"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
  return {
    IdleScreen,
    isIdle,
    countdown,
    onClose,
  };
};
