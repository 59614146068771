import styled from "styled-components";

export const UploadCustomButton = styled.button`
  padding: 10px 10px;
  background-color: #f5f5f5;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  color: #212121;
  justify-content: center;
  margin-bottom: 20px;
`;
