import { useState, useCallback } from "react";

export const useNav = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closeMenu = useCallback(() => {
    return setIsOpen(!isOpen);
  }, [isOpen]);

  return {
    isOpen,
    closeMenu,
  };
};
