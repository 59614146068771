import React from "react";

const ResultsLength = ({ state, page, data }) => {
  return (
    <div className="text-xs text-gray-800 mt-4 ml-2">
      <span className="">Showing </span>
      <span>{state.pageIndex * state.pageSize + 1}-</span>
      <span>
        {state.pageIndex * state.pageSize + page.length} of {data?.length}
      </span>
      <span> results</span>
    </div>
  );
};

export default ResultsLength;
