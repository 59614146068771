import { useQuery } from "@apollo/client";
import { QUERY } from "graphql-actions";
import jwt_decode from "jwt-decode";
import { useMemo } from "react";
import moment from "moment";

export const useTokenValidation = (token) => {
  const { data, loading } = useQuery(QUERY.USER.IS_TOKEN_VALID, {
    onError: (error) => console.log(error),
  });

  const isValid = useMemo(() => {
    if (token) {
      var decoded = jwt_decode(token);
      const expirationDate = moment.unix(decoded.exp);

      // Check if the token is expired
      const isTokenExpired = moment().isAfter(expirationDate);

      return data?.isTokenValid && !isTokenExpired;
    }
    return false;
  }, [data?.isTokenValid, token]);

  return {
    loading,
    isValid,
  };
};
