import { gql } from "@apollo/client";

export const ADD_ROOM = gql`
  mutation addRoom($input: RoomInput!) {
    addRoom(input: $input) {
      _id
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoom($_id: ID!, $input: RoomInput!) {
    updateRoom(_id: $_id, input: $input) {
      _id
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
    }
  }
`;

export const UPDATE_FAVOURITE_ROOMS = gql`
  mutation updateFavouriteRoom($_id: ID!) {
    updateFavouriteRoom(_id: $_id) {
      room {
        _id
      }
      user {
        _id
      }
    }
  }
`;
