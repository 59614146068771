import React from "react";
import Modal from "../Modal";

const ConfirmationModal = ({
  onClose,
  onSubmit,
  secondaryActionLabel,
  actionLabel,
  secondaryAction,
  title,
  disabled,
  isOpen,
  footer,
  bodyChild,
  actionClass,
  actionLoading,
  secondaryActionClass,
  secondaryActionLoading,
}) => {
  const bodyContent = <div className="text-md font-medium">{bodyChild}</div>;
  return (
    <Modal
      disabled={disabled}
      isOpen={isOpen}
      title={title ?? "Are you sure?"}
      actionClass={actionClass}
      actionLoading={actionLoading}
      actionLabel={actionLabel ?? "Cancel"}
      secondaryAction={secondaryAction}
      secondaryActionClass={secondaryActionClass}
      secondaryActionLabel={secondaryActionLabel ?? "Continue"}
      secondaryActionLoading={secondaryActionLoading}
      onClose={onClose}
      onSubmit={onSubmit}
      body={bodyContent}
      footer={footer}
    />
  );
};

export default ConfirmationModal;
