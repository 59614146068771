import React from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import clsx from "clsx";
import { BiSortAZ, BiSortAlt2, BiSortZA } from "react-icons/bi";
import useLogsTable from "./useLogsTable";
import { Table } from "components";

const Logs = () => {
  const { columns, data, loading } = useLogsTable();

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    {
      autoResetSortBy: false,
      autoResetPage: false,
      columns,
      data,
      getRowId: (row, index) => (row.id = index + 1),
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter } = state;
  const tableBodyClass =
    "block w-full table-fixed overflow-auto  bg-slate-100 mx-auto min-h-[60vh] transition-all duration-300";
  return (
    <div className="bg-white rounded-md p-6 pt-2">
      <div className=""></div>
      <div className="flex mt-4 mb-4 justify-between items-end">
        <Table.ShowEntries state={state} setPageSize={setPageSize} />
        <Table.Search
          placeholder={"Search name, address or etc"}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
      </div>
      <table
        {...getTableProps()}
        className="w-full border border-gray-200 block rounded-lg overflow-hidden"
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="pl-4">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    className={clsx("text-left pl-4 py-3 text-sm w-[15vw]", {
                      "border-gray-300 ": loading,
                      "!w-[5vw]": column.id === "id",
                      "!w-[15vw]": column.id === "action",
                      "!w-[30vw]": column.id === "message",
                    })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex items-center">
                      <div className="w-full">{column.render("Header")}</div>
                      <div className="mx-auto pr-6">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BiSortZA />
                          ) : (
                            <BiSortAZ />
                          )
                        ) : (
                          column.canSort && <BiSortAlt2 />
                        )}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={clsx(tableBodyClass, {
            "!min-h-[33vh] !h-[33vh]":
              loading || !page.length || !data.length || page.length < 6,
          })}
        >
          <Table.Status data={page} loading={loading} />
          {!loading &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white  border-t border-gray-200 cursor-pointer hover:bg-gray-50 transition-all duration-300"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={clsx("p-4 text-xs w-[15vw]", {
                          "!w-[5vw]": cell.column.id === "id",
                          "!w-[15vw]": cell.column.id === "action",
                          "!w-[30vw]": cell.column.id === "message",
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="flex justify-between">
        <Table.ResultsLength data={data} state={state} page={page} />
        <Table.Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          previousPage={previousPage}
          state={state}
        />
      </div>
    </div>
  );
};

export default Logs;
