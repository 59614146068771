import { gql } from "@apollo/client";

export const SIGN_UP_USER = gql`
  mutation ($input: RegisterUserInput) {
    signupUser(input: $input) {
      token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String) {
    forgotPassword(email: $email)
  }
`;

export const VERIFY_FORGOT_PASSWORD_LINK = gql`
  mutation ($_id: ID!, $token: String!) {
    verifyForgotPasswordLink(_id: $_id, token: $token)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
