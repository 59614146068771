import React, { useCallback } from "react";

import { BiEditAlt, BiTrashAlt } from "react-icons/bi";
import moment from "moment";
import { clsx } from "clsx";
import { useGetLogs } from "../../../../hooks/useGetLogs";

const useLogsTable = () => {
  const { data, loading } = useGetLogs();

  const onEdit = useCallback((data) => {
    alert("on edit");
    return data;
  }, []);

  const onDelete = useCallback((data) => {
    alert("on delete");

    return data;
  }, []);

  const columns = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
      },

      {
        Header: "Message",
        accessor: "message",
        Cell: ({ row }) => {
          const {
            original: { message },
          } = row;
          return (
            <div className="line-clamp-1">
              <div>{message}</div>
            </div>
          );
        },
      },
      {
        Header: "IP Address",
        accessor: "ipAddress",
      },
      {
        Header: "Level",
        accessor: "level",
        Cell: ({ row }) => {
          const {
            original: { level },
          } = row;
          return (
            <div
              className={clsx("p-2 text-center rounded-md capitalize w-20", {
                "!bg-orange-200 !text-orange-700": level === "warn",
                "bg-red-200 text-red-700": level === "error",
                "!bg-green-200 !text-green-700": level === "info",
              })}
            >
              {level}
            </div>
          );
        },
      },

      {
        Header: "Created Date",
        accessor: ({ timestamp }) => moment(timestamp).format("LL, HH:mm:s A"),
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="flex">
              <div
                className="mr-6 cursor-pointer"
                onClick={() => onEdit(original)}
              >
                <BiEditAlt size={20} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => onDelete(original)}
              >
                <BiTrashAlt size={20} />
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return {
    data,
    columns,
    loading,
  };
};

export default useLogsTable;
