import clsx from "clsx";
import React from "react";
import { ClipLoader } from "react-spinners";

const Status = ({ data, loading }) => {
  return (
    <div
      className={clsx("flex flex-col justify-center items-center h-[inherit]", {
        hidden: data?.length && !loading,
      })}
    >
      {loading && <ClipLoader width={280} height={4} color="#7cb73f" />}
      <div className="text-md font-semibold mt-4">
        {!data?.length && !loading ? "No data to display" : "Loading data"}
      </div>
    </div>
  );
};

export default Status;
