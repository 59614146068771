import styled from "styled-components";

export const StyledCheckboxLabel = styled.div`
  margin-bottom: 5;
  font-size: 12px;
  color: #6a6a6a;
  font-weight: 500;
`;

export const StyledCheckBoxButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 20px;
  :hover {
    opacity: 0.8;
  }
`;

export const StyledCheckBoxOptionLabel = styled.span`
  font-size: 12px;
  color: #6a6a6a;
  font-weight: 500;
  flex-wrap: wrap;
`;

export const StyledCheckBoxButtonCircle = styled.div`
  padding: 3px;
  border: 1px solid #000;
  height: 15px;
  width: 15px;
  border-radius: 10%;
  margin-left: 10px;
`;

export const StyledCheckBoxButtonInnerCircle = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => (props.active ? "#7CB73F" : "#transparent")};
  border-radius: 10%;
`;
