import React from "react";
import { clsx } from "clsx";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from "react-icons/bi";

const Pagination = ({
  canPreviousPage,
  gotoPage,
  previousPage,
  pageOptions,
  state,
  canNextPage,
  nextPage,
  pageCount,
}) => {
  const buttonClass = "mx-1 h-8 w-8 bg-gray-200 rounded-md cursor-pointer flex";
  const nextButtons = clsx(buttonClass, {
    "cursor-not-allowed opacity-50": !canNextPage,
  });
  const prevButtons = clsx(buttonClass, {
    "cursor-not-allowed opacity-50": !canPreviousPage,
  });
  return (
    <div className="flex justify-between items-center my-4">
      <div className="flex">
        <div
          className={prevButtons}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <BiChevronsLeft className="m-auto" />
        </div>
        <div
          className={prevButtons}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <BiChevronLeft className="m-auto" />
        </div>
        {pageOptions.map((pageIndex) => (
          <div
            key={pageIndex}
            className={clsx(buttonClass, {
              "!bg-black text-white": pageIndex === state.pageIndex,
            })}
            onClick={() => gotoPage(pageIndex)}
          >
            <div className="m-auto text-sm font-semibold">{pageIndex + 1}</div>
          </div>
        ))}
        <div
          className={nextButtons}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <BiChevronRight className="m-auto" />
        </div>
        <div
          className={nextButtons}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <BiChevronsRight className="m-auto" />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
