import { useLazyQuery, useQuery } from "@apollo/client";
import { QUERY } from "graphql-actions";
import { accountActions } from "../store/actions";
import { store } from "../store";

export const useGetCurrentUser = () => {
  const { data, loading, error, refetch } = useQuery(
    QUERY.USER.GET_CURRENT_USER,
    {
      fetchPolicy: "network-only",
      onCompleted: async ({ getCurrentUser }) => {
        await store.dispatch(
          accountActions.addAccountDetails(getCurrentUser || {})
        );
      },
    }
  );

  const [
    getCurrentUser,
    { data: userData, loading: userLoading, error: userError },
  ] = useLazyQuery(QUERY.USER.GET_CURRENT_USER, {
    fetchPolicy: "network-only",
    onCompleted: async ({ getCurrentUser }) => {
      await store.dispatch(
        accountActions.addAccountDetails(getCurrentUser || {})
      );
    },
  });

  return {
    fetchData: getCurrentUser,
    userData: data?.getCurrentUser ?? userData?.getCurrentUser ?? {},
    userLoading: loading || userLoading,
    userError: error || userError,
    refetch,
  };
};
