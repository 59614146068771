import React, { useState, createContext, useContext, useEffect } from "react";
import {
  removeAccountDetails,
  // addAccountDetails,
} from "../../store/actions/accountActions";
import { store } from "../../store";
// import { QUERY } from '../../graphql-actions';
// import { useLazyQuery } from "@apollo/client";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  const [localToken, setLocalToken] = useState(token);
  // const [getUser,{ data }] = useLazyQuery(QUERY.USER.GET_CURRENT_USER);

  useEffect(() => {
    if (!token) setLocalToken(null);
  }, [token]);

  const login = async (token) => {
    localStorage.setItem("token", token);
    setLocalToken(token);
    // await getUser()
  };

  const logout = () => {
    setLocalToken(null);
    localStorage.removeItem("token");
    return store.dispatch(removeAccountDetails());
  };

  // useEffect(() =>{
  //     if(data){
  //       store.dispatch(addAccountDetails(data?.getCurrentUser || {}))
  //     }
  // },[data])

  return (
    <AuthContext.Provider value={{ localToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
