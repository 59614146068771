import {
  ADD_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS,
  REMOVE_ACCOUNT_DETAILS,
} from "./actionTypes";

// ACCOUNT ACTIONS
export const addAccountDetails = (account) => ({
  type: ADD_ACCOUNT_DETAILS,
  account,
});

export const updateAccountDetails = (account) => ({
  type: UPDATE_ACCOUNT_DETAILS,
  account,
});

export const removeAccountDetails = () => ({
  type: REMOVE_ACCOUNT_DETAILS,
});
